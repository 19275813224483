@import "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap";
.cp-module--wrapper {
  justify-content: center;
  width: 100%;
  max-width: 100%;
  margin-top: 25px;
  display: flex;
}

.cp-module--wrapper .cp-module {
  color: #4c338c;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  display: flex;
}

@media only screen and (width <= 568px) {
  .cp-module--wrapper .cp-module {
    text-align: center;
    align-items: center;
    display: flex;
  }
}

.cp-module--wrapper .cp-module img {
  width: 150px;
  height: 150px;
}

.cp-module--wrapper .cp-module .content {
  max-width: 350px;
}

@media only screen and (width <= 568px) {
  .cp-module--wrapper .cp-module .content {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    display: flex;
  }
}

.cp-module--wrapper .cp-module .button-wrapper {
  flex-direction: column;
  display: flex;
}

.cp-module--wrapper .cp-module .button-wrapper .cp-button {
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (width <= 568px) {
  .cp-module--wrapper .cp-module .button-wrapper .cp-button {
    margin-top: 10px;
  }
}

.storybook-button {
  font-family: var(--button-font-family);
  text-transform: var(--button-text-transform);
  border-radius: var(--button-border-radius);
  cursor: pointer;
  border: 0;
  padding: 10px 22px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

.storybook-button.button-lm {
  margin-left: 25px;
}

.storybook-button.button-mt {
  margin-top: 10px;
}

.storybook-button.button-mb {
  margin-bottom: 10px;
}

.storybook-button:disabled {
  cursor: not-allowed;
}

.storybook-button--yellow {
  color: var(--button-label-color);
  background-color: var(--button-background-color);
  border: var(--button-border-width) solid var(--button-border-color);
}

.storybook-button--yellow.storybook-button--border {
  border: var(--button-border-width) solid var(--button-border-accent-color);
}

.storybook-button--yellow:hover {
  color: var(--button-label-hover-color);
  border: var(--button-border-width) solid var(--button-border-hover-color);
  background-color: var(--button-background-hover-color);
}

.storybook-button--yellow:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--yellow:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--purple {
  color: #f2c24e;
  border: var(--button-border-width) solid #4c338c;
  background-color: #4c338c;
}

.storybook-button--purple.storybook-button--border {
  border: var(--button-border-width) solid #f2c24e;
}

.storybook-button--purple:hover {
  color: #eb3c96;
  border: var(--button-border-width) solid #eb3c96;
}

.storybook-button--purple:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #f2c24e;
}

.storybook-button--purple:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--white {
  color: var(--button-outline-label-color);
  background-color: var(--button-outline-background-color);
  border: var(--button-border-width) solid var(--button-outline-border-color);
}

.storybook-button--white.storybook-button--border {
  border: var(--button-border-width) solid var(--button-outline-border-color);
}

.storybook-button--white:hover {
  color: var(--button-outline-label-hover-color);
  border: var(--button-border-width) solid var(--button-outline-border-hover-color);
  background: var(--button-outline-background-hover-color);
}

.storybook-button--white:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--white:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--transparent {
  color: #4c338c;
  background-color: #0000;
}

.storybook-button--link {
  color: var(--button-outline-label-color);
  font-size: var(--button-font-size);
  background-color: #0000;
  padding: 0 !important;
}

.storybook-button--red {
  color: #d10101;
  border: var(--button-border-width) solid #d10101;
}

.storybook-button--red.storybook-button--border {
  border: var(--button-border-width) solid #d10101;
}

.storybook-button--red:hover {
  color: #eb3c96;
  border: var(--button-border-width) solid #eb3c96;
}

.storybook-button--red:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--red:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--success {
  color: var(--button-label-color);
  background-color: var(--success-background-color);
  border: var(--button-border-width) solid var(--success-background-color);
}

.storybook-button--success.storybook-button--border {
  border: var(--button-border-width) solid var(--success-background-color);
}

.storybook-button--success:hover {
  color: var(--button-label-hover-color);
  border: var(--button-border-width) solid var(--success-background-hover-color);
  background-color: var(--success-background-hover-color);
}

.storybook-button--success:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--success:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--error {
  color: var(--button-label-color);
  background-color: var(--error-background-color);
  border: var(--button-border-width) solid var(--error-background-color);
}

.storybook-button--error.storybook-button--border {
  border: var(--button-border-width) solid var(--error-background-color);
}

.storybook-button--error:hover {
  color: var(--button-label-hover-color);
  border: var(--button-border-width) solid var(--error-background-hover-color);
  background-color: var(--error-background-hover-color);
}

.storybook-button--error:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--error:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--warning {
  color: var(--button-label-color-inverted);
  background-color: var(--warning-background-color);
  border: var(--button-border-width) solid var(--warning-background-color);
}

.storybook-button--warning.storybook-button--border {
  border: var(--button-border-width) solid var(--warning-background-color);
}

.storybook-button--warning:hover {
  color: var(--button-label-hover-color-inverted);
  border: var(--button-border-width) solid var(--warning-background-hover-color);
  background-color: var(--warning-background-hover-color);
}

.storybook-button--warning:disabled {
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

.storybook-button--warning:disabled:hover {
  color: #4c338c;
  border: var(--button-border-width) solid #edeeea;
  background-color: #edeeea;
}

@keyframes load {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.loader {
  border: 4px solid #fff3;
  border-left-color: currentColor;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: 1s linear infinite load;
}

.spacing {
  width: 100%;
  display: flex;
}

.spacing.fullWidthChildren > * {
  flex-grow: 1;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-color);
  margin: 10px 0;
}

h1.secondary, h2.secondary, h3.secondary, h4.secondary, h5.secondary, h6.secondary {
  color: #000;
}

h1.red, h2.red, h3.red, h4.red, h5.red, h6.red {
  color: #da3759;
  text-decoration: underline;
}

p {
  color: var(--text-color);
  text-align: left;
  margin: 10px 0;
}

p.disabled {
  color: gray;
}

p.secondary {
  color: #000;
}

p.error {
  color: #da3759;
}

p.strong {
  font-weight: bold;
}

p.note {
  font-size: 12px;
  line-height: 160%;
}

.icon.icon--colorize path, .icon.icon--colorize circle {
  stroke: currentColor;
  fill: none;
}

.card-group-header {
  background-color: #fff;
}

.card-group-header .icon-wrapper {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  display: flex;
}

.card-group-header .icon-wrapper.success {
  background-color: var(--card-group-header-bakground-success-color);
}

.card-group-header .icon-wrapper.warning {
  background-color: var(--card-group-header-bakground-warning-color);
}

.card-group-header .icon-wrapper.info {
  background-color: var(--card-group-header-bakground-info-color);
}

.card-group-header .title {
  margin-top: 4px;
  font-size: 26px;
  font-weight: bold;
  line-height: 140%;
}

.card-group-header .description {
  color: #5e5e72;
  font-size: 16px;
  line-height: 160%;
}

footer {
  background: var(--footer-background-color);
  min-height: 5vh;
  margin-top: auto;
}

@media screen and (width <= 480px) {
  footer {
    clear: left;
    font-size: 12px;
  }
}

@media screen and (width <= 919px) {
  footer #copyright {
    width: 100%;
  }
}

@media screen and (width >= 920px) {
  footer hr {
    display: none;
  }
}

footer a, footer span {
  color: var(--footer-link-color) !important;
}

footer .main-container-footer {
  text-align: center;
  margin: var(--footer-spacing);
}

footer #versionNumber {
  width: 100%;
  color: var(--footer-text-color);
  justify-content: center;
  margin-top: 5px;
  font-size: 12px;
  display: flex;
}

footer .content-container-footer {
  flex-flow: wrap;
  place-content: start center;
  display: flex;
}

footer .footer-item {
  white-space: nowrap;
  flex: none;
  padding: 0 10px;
}

footer .footer-item a {
  text-decoration: underline;
}

footer .pb-0 {
  padding-bottom: 0 !important;
}

footer hr {
  border: .5px solid var(--separator-color);
  margin: 12px 0;
}

footer #copyright {
  white-space: unset;
  margin-top: 10px;
}

footer #copyright p {
  word-break: break-word;
  text-align: center;
  color: var(--footer-text-color);
}

footer #love {
  white-space: unset;
  cursor: default;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  display: flex;
  position: relative;
}

footer #love p {
  word-break: break-word;
  text-align: center;
  color: var(--footer-text-color);
  margin: 5px 0 0;
}

footer #love #footer-emoji {
  margin: 0 5px;
  padding-top: 4px;
}

footer #love #footer-emoji:hover {
  animation: 2s infinite pulse;
  transform: scale(2);
}

@keyframes pulse {
  0% {
    transform: scale(.95);
  }

  70% {
    transform: scale(1.5);
  }

  75% {
    transform: scale(1.6);
  }

  100% {
    transform: scale(.95);
  }
}

.link {
  color: var(--link-color);
  cursor: pointer;
  margin: 10px 0;
  text-decoration: underline;
}

.link.secondary {
  color: #000;
}

.link.gray {
  color: gray;
}

.link.purple {
  color: #4c338c !important;
}

.link:not(.underline) {
  text-decoration: none;
}

.link.strong {
  font-weight: bold;
}

nav {
  background-color: var(--menu-background-color);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 16px;
  display: flex;
  position: relative;
}

nav.active {
  background-color: var(--menu-background-active-color);
}

nav.active .navigation-wrapper {
  background-color: var(--menu-background-active-color);
  z-index: 1001;
  height: calc(100vh - 16px);
  box-shadow: 0px 0px 5px 0px var(--menu-boxshadow-color);
  border-radius: 0;
  width: 100%;
  max-width: 320px;
  position: absolute;
  top: 0;
  right: 0;
}

nav.active .navigation-welcome {
  padding: 24px 32px 0;
}

nav.active .navigation-welcome .name {
  color: var(--menu-text-color);
  margin: 0;
  font-size: 18px;
  line-height: 140%;
}

nav.active .navigation-welcome .mail {
  color: var(--menu-text-color);
  margin: 4px 0 0;
  font-size: 14px;
  line-height: 140%;
}

nav.active .navigation-header {
  width: 100%;
}

nav.active h4 {
  color: var(--menu-text-color);
}

nav.active .storybook-button {
  margin-top: 40px;
}

nav .navigation-title {
  color: var(--header-text-color);
}

nav p, nav h1, nav h2, nav h3, nav h4, nav h5, nav h6, nav img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

nav .navigation-menu-heading {
  cursor: pointer;
  padding-right: 10px;
}

nav .navigation-menu-heading h4 {
  color: var(--menu-text-color);
}

nav .navigation-header {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

nav .navigation-header p {
  color: var(--menu-text-color);
  margin: 0;
}

nav .navigation-header p:first-child {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  font-size: 14px;
  display: -webkit-box;
  overflow: hidden;
}

nav .navigation-header p:nth-child(2) {
  color: var(--menu-text-color);
  font-size: 8px;
}

nav .navigation-header h3, nav .navigation-header h4 {
  margin: 0;
}

nav .navigation-header .toggle-container {
  cursor: pointer;
  fill: #fff;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: inline-block;
}

nav .navigation-header .toggle-container path {
  stroke: #fff;
}

nav .navigation-content {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 40px 32px;
  display: flex;
}

nav .navigation-content .navigation-entry {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  gap: 8px;
  width: 100%;
  line-height: 32px;
  display: flex;
}

nav .navigation-content .navigation-entry svg {
  color: var(--menu-link-color);
}

nav .navigation-content .navigation-entry p {
  color: var(--menu-link-color);
  font-size: 14px;
}

nav .navigation-content .navigation-entry p, nav .navigation-content .navigation-entry h5 {
  margin: 0;
}

nav .navigation-content .navigation-entry:hover {
  background: var(--menu-item-background-color);
}

nav .navigation-content .navigation-entry .navigation-entry-note {
  flex-direction: column;
  display: flex;
}

nav .navigation-content .navigation-entry .navigation-entry-note p:nth-child(2) {
  color: var(--menu-link-color);
  font-size: 8px;
}

nav .navigation-content .navigation-entry .toggle-wrapper {
  align-self: start;
  height: 34px;
  transform: scale(.75);
}

nav .navigation-content .navigation-entry .toggle-wrapper .toggle {
  height: 34px;
}

nav .navigation-content .navigation-entry .toggle-wrapper .toggle .toggle-item {
  margin: 0;
}

.toggle-wrapper .disabled label.toggle-item {
  background: #edeeea;
}

.toggle-wrapper .disabled .check {
  background: #a6a8a7;
}

.toggle-wrapper .disabled label.toggle-item:before {
  border-color: #a6a8a7;
}

.toggle-wrapper .disabled .toggle-input:checked + label .check {
  background: #a6a8a7;
}

.toggle-wrapper .toggle {
  display: inline-block;
  position: relative;
}

.toggle-wrapper label.toggle-item {
  transform-origin: 20%;
  cursor: pointer;
  background: #edeeea;
  border: 1px solid #a6a8a7;
  border-radius: 50px;
  width: 60px;
  height: 31px;
  margin: 10px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.toggle-wrapper label.toggle-item:before {
  border: 2px solid #c34a4a;
  border-radius: 32px;
  width: 20px;
  height: 20px;
  transition: all .3s;
  display: block;
  top: 5px;
  left: 5px;
}

.toggle-wrapper input {
  opacity: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-wrapper .toggle-input:checked + label .check {
  background: #8bc34a;
  left: 30.7px;
  transform: rotate(360deg);
}

.toggle-wrapper .check {
  background: #c34a4a;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: all .4s;
  position: absolute;
  top: 2.3px;
  left: 2.3px;
}

.authenticatorList {
  color: #000;
  flex-direction: column;
  display: flex;
}

.authenticatorList .column {
  width: 100%;
}

.authenticatorList_header {
  color: #4c338c;
  background-color: #fff;
  flex-direction: row;
  display: flex;
}

.authenticatorList_header h3 {
  margin: 10px 0;
}

.authenticatorListEntry {
  color: #4c338c;
  border: var(--card-border);
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  margin-top: 20px;
  padding: 5px 15px;
  display: flex;
}

.authenticatorListEntry.empty {
  width: 100%;
  max-width: unset;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

@media screen and (width <= 600px) {
  .authenticatorListEntry.empty {
    max-width: 600px;
  }
}

.authenticatorListEntry .entry-header {
  flex-direction: column;
  display: flex;
}

.authenticatorListEntry .entry-header .entry-header-upper-row {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  display: flex;
}

.authenticatorListEntry .entry-header .entry-header-upper-row .entry-icon {
  width: 30px;
  height: 30px;
}

.authenticatorListEntry .entry-header .entry-header-upper-row .name {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 80%;
  padding-left: 8px;
  display: flex;
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow {
  justify-content: center;
  align-items: center;
  width: 5%;
  display: flex;
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow .toggle-container {
  cursor: pointer;
  -o-transition: all .3s ease;
  width: 24px;
  height: 24px;
  transition: all .3s;
  animation: 5s fade-out;
  display: inline-block;
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow .toggle-container .line {
  background: #4c338c;
  width: 12px;
  height: 2px;
  margin: 5px auto;
  display: block;
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow .toggle-container .bottom {
  transform: translateY(2px)rotateZ(225deg);
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow .toggle-container .top {
  transform: translateY(2px)rotateZ(135deg);
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow .toggle-container.inactive {
  transform: translateY(2px)rotate(-90deg);
}

.authenticatorListEntry .entry-header .entry-header-upper-row .arrow .toggle-container.active {
  transform: translateY(2px)rotate(-270deg);
}

.authenticatorListEntry .entry-header .entry-header-lower-row {
  gap: 5px;
  width: 100%;
  margin: 10px 0;
  display: flex;
}

.authenticatorListEntry .entry-header .entry-header-lower-row h4, .authenticatorListEntry .entry-header .entry-header-lower-row p {
  margin: 0;
}

.authenticatorListEntry .entry-header .entry-header-lower-row .authenticatorList_content_item_value {
  word-break: break-word;
}

.authenticatorListEntry .entry-header .entry-header-lower-row .imgAndText {
  flex-direction: row;
  align-items: center;
  gap: 5px;
  display: flex;
}

.authenticatorListEntry .entry-header .entry-header-lower-row.fdr {
  flex-direction: row;
  align-items: center;
}

.authenticatorListEntry .entry-header .entry-header-lower-row.fdc {
  flex-direction: column;
  gap: 0;
}

.authenticatorListEntry .entry-content {
  flex-direction: row;
  display: flex;
}

.authenticatorListEntry .authenticatorList_name_edit {
  width: 80%;
}

.authenticatorListEntry .authenticatorList_name_edit input {
  caret-color: #fff;
  background: #f2c24e;
  border: none;
  font-size: 16px;
  font-weight: bold;
}

.authenticatorListEntry .authenticatorList_name_edit input:focus {
  outline-width: 0;
}

.authenticatorListEntry .authenticatorList_row .textInput_input {
  width: 100%;
}

.authenticatorListEntry .authenticatorList_row .authenticatorList_row_icon {
  justify-content: center;
  align-items: center;
  width: 10%;
  display: flex;
}

.authenticatorListEntry .authenticatorList_row .authenticatorList_row_empty {
  align-items: center;
  width: 90%;
  margin-right: 10px;
  display: flex;
  overflow: hidden;
}

.authenticatorListEntry .authenticatorList_row .authenticatorList_row_empty p {
  color: #000;
}

.authenticatorListEntry .authenticatorList_content_item {
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
}

.authenticatorListEntry .authenticatorList_content_item h4, .authenticatorListEntry .authenticatorList_content_item p {
  margin: 0;
}

.authenticatorListEntry .authenticatorList_content_item .authenticatorList_content_item_value {
  word-break: break-word;
}

@media screen and (width <= 600px) {
  .authenticatorListEntry .authenticatorList_content_item {
    justify-content: flex-start;
  }
}

.authenticatorListEntry .authenticatorList_content {
  color: #4c338c;
  background-color: #fff;
  flex-direction: column;
  display: flex;
}

.authenticatorListEntry .authenticatorList_content.hidden {
  display: none !important;
}

.authenticatorListEntry .authenticatorList_content_buttons {
  background-color: #fff;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  display: flex;
}

.authenticatorListEntry .authenticatorList_content_buttons button {
  width: 100%;
}

@media screen and (width <= 600px) {
  .authenticatorListEntry .authenticatorList_content_buttons {
    justify-content: center;
  }
}

.authenticatorListEntry .authenticatorList_content_buttons p {
  text-align: center;
}

.authenticatorListEntry hr {
  border: none;
  width: 100%;
  margin: 3px 0;
}

.authenticatorListEntry hr.mobile {
  display: none;
}

@media screen and (width <= 600px) {
  .authenticatorListEntry hr.mobile {
    display: block;
  }
}

.authenticatorListEntry hr.light {
  border-bottom: 2px solid #edeeea;
}

.authenticatorListEntry hr.dark {
  border-bottom: 2px solid #a6a8a7;
}

.authenticatorListEntry .might-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.authenticatorListEntry .might-overflow:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
}

.authenticatorListEntry .not-in-active-usage-warning {
  background-color: #f6edd0;
  border: 2px solid #ffc832;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
  padding: 5px 10px;
  display: flex;
}

.authenticatorListEntry .not-in-active-usage-warning p {
  margin: 0;
}

@media screen and (width <= 1150px) {
  .onlyOneAuthInfo .icon-size {
    width: 20px;
    height: 20px;
  }
}

@media screen and (width <= 900px) {
  .onlyOneAuthInfo .explanation {
    flex-direction: column;
  }
}

@media screen and (width <= 900px) {
  .onlyOneAuthInfo .explanation .explanation-card {
    width: 100%;
  }
}

@media screen and (width <= 950px) {
  .onlyOneAuthInfo .explanation .plus p {
    font-size: 20px;
  }
}

.onlyOneAuthInfo .explanation .expected .authenticators {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.textInput {
  flex-direction: column;
  display: flex;
}

.textInput .textInput_label {
  color: var(--input-label-color);
  font-size: var(--input-label-font-size);
  margin-bottom: 10px;
  line-height: 120%;
}

.textInput .textInput_input {
  border: var(--input-border-width) solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  height: var(--input-height);
  padding: 8px;
}

.textInput .textInput_input:focus {
  outline-width: 0;
}

.textInput .textInput-notifications {
  gap: 10px;
  display: flex;
}

.textInput .textInput-notifications .textInput_message {
  align-self: flex-start;
  margin-top: 10px;
}

.textInput .textInput-notifications .textInput_message .textInput_text {
  border-radius: 5px;
  min-width: 48px;
  margin: 0;
  padding: 8px;
  font-size: 12px;
}

.textInput .textInput-notifications .textInput_message .textInput_text.error {
  background-color: var(--error-background-color);
  color: var(--error-link-color);
}

.textInput .textInput-notifications .textInput_message .textInput_text.warning {
  background-color: var(--warning-background-color);
  color: var(--warning-link-color);
}

.textInput .textInput-notifications .textInput_message .textInput_corner {
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  transform: translate(16px, -7px);
}

.textInput .textInput-notifications .textInput_message .textInput_corner.error {
  border-bottom: 7px solid var(--error-background-color);
}

.textInput .textInput-notifications .textInput_message .textInput_corner.warning {
  border-bottom: 7px solid var(--warning-background-color);
}

.textInput .input-password-wrapper {
  position: relative;
}

.textInput .input-password-wrapper input {
  width: 100%;
}

.textInput .input-password-wrapper input.icon {
  padding-left: 30px;
}

.textInput .input-password-wrapper .img-left {
  margin: 3px;
  position: absolute;
  top: 4px;
  left: 5px;
}

.textInput .input-password-wrapper .img-right {
  cursor: pointer;
  margin: 3px;
  position: absolute;
  top: 4px;
  right: 5px;
}

.locked {
  z-index: 1000;
  position: fixed;
  inset: 0;
  background-color: #00000080 !important;
}

.modal-wrapper {
  min-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (width <= 568px) {
  .modal-wrapper {
    width: 85%;
    min-width: unset;
  }

  .modal-wrapper p {
    font-size: 12px;
  }
}

@media only screen and (width >= 568px) and (width <= 896px) {
  .modal-wrapper {
    width: 50%;
    min-width: unset;
  }
}

.modal-wrapper .modal {
  color: var(--text-color);
  overflow-wrap: anywhere;
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

@media only screen and (width <= 568px) {
  .modal-wrapper .modal {
    padding: 10px;
  }
}

.modal-wrapper .modal .modal-content {
  width: 100%;
  max-height: 55vh;
  padding-right: 10px;
  display: block;
  overflow: auto;
}

.modal-wrapper .modal .modal-buttons {
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
  display: flex;
}

@media only screen and (width <= 1200px) {
  .modal-wrapper .modal .modal-buttons {
    flex-direction: column-reverse;
  }
}

.modal-wrapper .modal .modal-buttons button {
  min-width: 150px;
}

.modal-wrapper .modal .modal-header {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.modal-wrapper .modal .modal-header .modal-header-button {
  width: 25px;
}

.modal-wrapper .modal .modal-header .modal-icon-heading-wrapper {
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 95%;
  display: flex;
}

.modal-wrapper .modal .modal-header .modal-icon-heading-wrapper .modal-header-title {
  width: 100%;
  padding-left: 5px;
  font-size: 16px;
}

@media only screen and (width <= 568px) {
  .modal-wrapper .modal .modal-header .modal-icon-heading-wrapper .modal-header-title {
    width: 75%;
    font-size: 14px;
  }
}

.modal-wrapper .modal .modal-header .modal-icon-heading-wrapper .modal-header-title {
  margin: 0;
}

.modal-wrapper .modal .modal-header .modal-icon, .modal-wrapper .modal .modal-header .modal-close-button {
  width: 20px;
  height: 20px;
}

.modal-wrapper .modal .modal-close-button:hover {
  cursor: pointer;
}

.storybook-icon-button {
  background: none;
  border: none;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.storybook-icon-button h3 {
  color: var(--text-color);
}

.storybook-icon-button h2 {
  margin: 0;
  font-size: 24px;
}

@media screen and (width <= 600px) {
  .storybook-icon-button h2 {
    font-size: 18px;
  }
}

.storybook-icon-button img {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.storybook-icon-button img.active {
  transform: none;
}

.onlyOneAuthInfo {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  max-width: 906px;
  display: flex;
}

.onlyOneAuthInfo .icon-size {
  width: 30px;
  height: 30px;
}

@media screen and (width <= 1150px) {
  .onlyOneAuthInfo .icon-size {
    width: 20px;
    height: 20px;
  }
}

.onlyOneAuthInfo .information {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.onlyOneAuthInfo .information p {
  text-align: unset;
}

.onlyOneAuthInfo .explanation {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

@media screen and (width <= 900px) {
  .onlyOneAuthInfo .explanation {
    flex-direction: column;
  }
}

.onlyOneAuthInfo .explanation .explanation-card {
  background-color: #f3f4f2;
  border-radius: 6px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 45%;
  padding: 10px;
  display: flex;
}

@media screen and (width <= 900px) {
  .onlyOneAuthInfo .explanation .explanation-card {
    width: 100%;
  }
}

.onlyOneAuthInfo .explanation .explanation-card p {
  margin: 0;
}

.onlyOneAuthInfo .explanation .explanation-card h3 {
  text-align: center;
  margin: 0;
}

.onlyOneAuthInfo .explanation .current .authInfo {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.onlyOneAuthInfo .explanation .current p {
  margin: 0;
}

.onlyOneAuthInfo .explanation .plus {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.onlyOneAuthInfo .explanation .plus p {
  font-size: 40px;
}

@media screen and (width <= 950px) {
  .onlyOneAuthInfo .explanation .plus p {
    font-size: 20px;
  }
}

.onlyOneAuthInfo .explanation .expected .authenticators {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.onlyOneAuthInfo .explanation .expected .authenticators div {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

.authenticationStatus {
  border: var(--card-border);
  background-color: #fff;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
}

.authenticationStatus .authenticationStatus-content-side {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.authenticationStatus .authenticationStatus-content-wrapper {
  flex-direction: column;
  margin-left: 16px;
  display: flex;
}

.authenticationStatus h3, .authenticationStatus p {
  margin: 0;
}

.authenticationStatus .action-wrapper {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}

.authenticationStatus .action-wrapper button {
  width: fit-content;
}

.authenticationStatus .action-wrapper label {
  margin-bottom: 0 !important;
}

.personal-usercard-wrapper {
  width: 100%;
}

.personal-usercard-wrapper .portalUserCard {
  border: var(--card-border);
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 20px 0;
  padding: 10px 15px;
  display: flex;
}

.personal-usercard-wrapper .portalUserCard h3, .personal-usercard-wrapper .portalUserCard p {
  margin: 0;
}

.personal-usercard-wrapper .portalUserCard .personal-usercard-content {
  flex-direction: row;
  width: 100%;
  display: flex;
}

@media screen and (width <= 600px) {
  .personal-usercard-wrapper .portalUserCard .personal-usercard-content {
    flex-direction: column;
    gap: 20px;
  }
}

.personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-userinfo-wrapper {
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
  width: 50%;
  padding: 0 10px;
  display: flex;
}

@media screen and (width <= 600px) {
  .personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-userinfo-wrapper {
    width: 100%;
  }
}

.personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-userinfo-wrapper .personal-usercard-userinfo {
  flex-direction: column;
  align-items: center;
  gap: 5px;
  display: flex;
}

.personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-userinfo-wrapper p {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  text-align: center;
}

.personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-buttongroup {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50%;
  display: flex;
}

@media screen and (width <= 600px) {
  .personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-buttongroup {
    width: 100%;
  }
}

.personal-usercard-wrapper .portalUserCard .personal-usercard-content .personal-usercard-buttongroup button {
  width: 100%;
}

.managed-usercard-wrapper {
  flex-direction: row;
  gap: 20px;
  width: 100%;
  display: flex;
}

@media screen and (width <= 600px) {
  .managed-usercard-wrapper {
    flex-direction: column;
    gap: 0;
  }
}

.managed-usercard-wrapper .portalUserCard {
  border: var(--card-border);
  background-color: #fff;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 390px;
  margin: 10px 0;
  padding: 10px 15px;
  display: flex;
}

@media screen and (width <= 600px) {
  .managed-usercard-wrapper .portalUserCard {
    max-width: 600px;
  }
}

.managed-usercard-wrapper .portalUserCard h3, .managed-usercard-wrapper .portalUserCard p {
  margin: 0;
}

.managed-usercard-wrapper .portalUserCard .portalUserCard-userinfo {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
}

.managed-usercard-wrapper .portalUserCard .portalUserCard-userinfo p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.managed-usercard-wrapper .portalUserCard button {
  width: 100%;
}

.radiobutton-group {
  background: var(--background-color);
  margin-top: 25px;
}

.radiobutton-group h3 {
  color: var(--text-color);
}

.radiobutton-group .hide {
  display: none !important;
}

.radiobutton-group hr {
  border: .5px solid var(--separator-color);
}

.radiobutton-group.no-background {
  background: none !important;
}

.radiobutton-wrapper {
  background: var(--background-color);
  z-index: 1;
  flex-direction: column;
  align-items: start;
  max-width: 906px;
  margin: 20px 0;
  display: flex;
}

.radiobutton-wrapper.highlight {
  border: 2px solid #f2c24e;
  padding: 5px;
}

.radiobutton-wrapper.no-highlight {
  padding-left: 7px;
}

.radiobutton-wrapper .disabled {
  color: gray;
}

.radiobutton-wrapper .font-weight-normal {
  font-weight: normal;
}

.radiobutton-wrapper .recommended-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: -17px;
  display: flex;
}

.radiobutton-wrapper .recommended-container .recommended {
  color: var(--text-color);
  background: #f2c24e;
  padding: 3px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
}

.radiobutton-wrapper .recommended-container p {
  background: var(--background-color);
  z-index: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 10px;
}

@media screen and (width <= 420px) {
  .radiobutton-wrapper .recommended-container p {
    padding: 0 2px;
    font-size: 8px;
  }
}

.radiobutton-wrapper .radiobutton-input-row {
  font-family: var(--font-family);
  color: var(--text-color);
  align-items: center;
  font-weight: bold;
  display: flex;
}

@media screen and (width <= 420px) {
  .radiobutton-wrapper .radiobutton-input-row {
    padding-top: 5px;
  }
}

.radiobutton-wrapper .radiobutton-input-row .input {
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.radiobutton-wrapper .radiobutton-input-row .input input {
  accent-color: #d43854;
  border: none;
  width: 20px;
  height: 20px;
  margin: 0;
}

.radiobutton-wrapper .radiobutton-input-row .image {
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  display: flex;
}

.radiobutton-wrapper .radiobutton-input-row .image.disabled {
  filter: contrast(0);
}

.radiobutton-wrapper .radiobutton-input-row .image img {
  width: 30px;
  height: 30px;
}

.radiobutton-wrapper .radiobutton-input-row .text {
  word-break: break-all;
}

.radiobutton-wrapper .radiobutton-content-row {
  flex-direction: row;
  justify-content: left;
  display: flex;
}

@media screen and (width <= 500px) {
  .radiobutton-wrapper .radiobutton-content-row {
    flex-direction: column;
  }
}

.radiobutton-wrapper .radiobutton-content-row .textonly {
  width: 100%;
}

.radiobutton-wrapper .radiobutton-content-row .image {
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: flex;
}

.radiobutton-wrapper .radiobutton-content-row .image img {
  width: 175px;
}

.radiobutton-wrapper .radiobutton-content-row.listing {
  flex-direction: column;
}

.radiobutton-wrapper .radiobutton-content-row.listing .step {
  flex-direction: row;
  display: flex;
}

@media screen and (width <= 500px) {
  .radiobutton-wrapper .radiobutton-content-row.listing .step {
    flex-direction: column;
    margin-top: 5px;
  }

  .radiobutton-wrapper .radiobutton-content-row.listing .step p, .radiobutton-wrapper .radiobutton-content-row.listing .step div {
    margin: 2px;
  }
}

.radiobutton-wrapper .radiobutton-content-row.listing .step p, .radiobutton-wrapper .radiobutton-content-row.listing .step div {
  margin-left: 5px;
}

.radiobutton-wrapper .radiobutton-content-row.tabulator {
  width: 75px;
}

.radiobutton-wrapper .radiobutton-content-row.disable-description {
  margin-left: 70px;
}

.radiobutton-wrapper .radiobutton-content-row.disable-description p {
  color: gray;
}

.radiobutton-wrapper .radiobutton-content-row .textarea {
  border-color: var(--menu-border-color);
  resize: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}

.radiobutton-wrapper .radiobutton-content-row .textarea:focus {
  outline: none !important;
}

@media screen and (width <= 600px) {
  .radiobutton-wrapper .radiobutton-content-row .textarea {
    font-size: 10px;
  }
}

.radiobutton-wrapper.no-background {
  background: none !important;
}

.radiobutton_group_no_more_options {
  margin-left: 29px;
}

.storybook-box {
  padding: var(--infobox-padding);
  box-shadow: var(--infobox-box-shadow);
  overflow-wrap: anywhere;
  border-radius: 6px;
  flex-direction: column;
  gap: 5px;
  display: flex;
  overflow: hidden;
}

.storybook-box .storybook-headline {
  line-height: 140%;
  margin: 0 !important;
}

.storybook-box .storybook-subtitle {
  line-height: 160%;
  margin-top: 4px !important;
  margin-bottom: 0 !important;
}

.storybook-box svg {
  flex-shrink: 0;
}

.storybook-info-box {
  background-color: var(--infobox-background-info-color);
  border: var(--infobox-border-info);
}

.storybook-info-box .storybook-headline {
  color: var(--infobox-text-info-color);
}

.storybook-error-box {
  background-color: var(--infobox-background-error-color);
  border: var(--infobox-border-error);
}

.storybook-error-box .storybook-headline {
  color: var(--infobox-text-error-color);
}

.storybook-warning-box {
  background-color: var(--infobox-background-warning-color);
  border: var(--infobox-border-warning);
}

.storybook-warning-box .storybook-headline {
  color: var(--infobox-text-warning-color);
}

.storybook-success-box {
  background-color: var(--infobox-background-success-color);
  border: var(--infobox-border-success);
}

.storybook-success-box .storybook-headline {
  color: var(--infobox-text-success-color);
}

.storybook-info-box--content {
  align-items: flex-start;
  gap: var(--infobox-gap);
  display: flex;
}

.storybook-info-box--icon {
  width: 20px;
  height: 20px;
}

.storybook-close {
  width: 20px;
  height: 20px;
  margin-left: auto;
}

.storybook-close-error {
  color: var(--infobox-text-error-color);
}

.storybook-close-info {
  color: var(--infobox-text-info-color);
}

.storybook-close-warning {
  color: var(--infobox-text-warning-color);
}

.storybook-close-success {
  color: var(--infobox-text-success-color);
}

.storybook-info-box--close-button:hover {
  cursor: pointer;
}

.storybook-info-box--buttons {
  justify-content: flex-end;
  display: flex;
}

.avatar-card:not(.no-card) {
  background-color: var(--background-color);
  border: var(--card-border);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.avatar-card .name {
  font-weight: 600;
}

.avatar-card .description, .avatar-card .mobile-number {
  color: var(--text-color-muted);
}

.avatar-card .manage-accounts, .avatar-card .avatar-image {
  flex-grow: 0;
}

.avatar-card .initial-avatar {
  color: #fff;
  background-color: #d1d5db;
  border-radius: 50%;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  display: flex;
}

.card-group {
  background-color: #fff;
  border: 1px solid #b4b4c1;
  border-radius: 8px;
  padding: 0;
}

.card-group button {
  border-radius: none;
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.card-group-item {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  padding: 16px;
  border-bottom: 1px solid #b4b4c1 !important;
}

.card-group-item.is-clickable {
  cursor: pointer;
}

.card-group-item.parent {
  background-color: var(--card-group-background-color-parent);
}

.card-group-item.parent:first-child {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.card-group-item:last-child {
  border-bottom: none !important;
}

.card-group-item .label {
  flex-grow: 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 140%;
}

.card-group-item .description {
  color: #5e5e72;
  text-align: left;
  width: 100%;
}

.card-group-item .action-wrapper {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.card-group-item .action-wrapper .icon {
  color: var(--link-color);
}

.card-group-item .icon {
  flex-shrink: 0;
}

.card-group-item ul {
  margin: 0;
  padding: 0;
  list-style: outside;
}

.card-group-item ul strong {
  margin-right: 4px;
}

.card-group-item ul li p, .card-group-item ul li h4 {
  color: var(--text-color-muted);
  display: inline;
}

.card-group-item ul li h4 {
  margin-right: 4px;
}

.card-group-item .toggle-item {
  margin: 0 !important;
}

.badge {
  border-radius: 100px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  flex-grow: 0 !important;
}

.badge.badge-warning {
  background-color: #ffc832;
}

.badge.badge-success {
  color: #01884c;
  background-color: #fff;
  border: 1px solid #01884c;
}

.badge.badge-info {
  color: #fff;
  background-color: #0f69af;
}

#loading {
  background: var(--loading-background-color);
  z-index: 20;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#loading .loading-side {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  width: 25%;
  display: flex;
  overflow: auto;
}

#loading .loading-side::-webkit-scrollbar {
  display: none;
}

#loading .loading-side .scroll-animated {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#loading .loading-side h1, #loading .loading-side h2, #loading .loading-side h3, #loading .loading-side h4, #loading .loading-side h5, #loading .loading-side h6, #loading .loading-side p, #loading .loading-side a {
  color: #fff;
}

#loading .loading-side img {
  width: 80%;
}

@media screen and (width <= 750px) {
  #loading .loading-side {
    display: none;
  }
}

#loading .loading-middle {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

#loading .loading-text {
  text-align: center;
}

#loading .loading-heading {
  color: #fff;
  text-align: center;
}

#loading .loading-animation-wrapper {
  justify-content: center;
  width: 100%;
  height: 20px;
  margin: 30px 0;
  display: flex;
}

#loading .loading-animation-wrapper .loading-animation {
  background-color: var(--loading-foreground-color);
  color: var(--loading-foreground-color);
  border-radius: 12px;
  width: 20px;
  height: 20px;
  animation: 1s linear .5s infinite alternate loading-animation;
  position: relative;
}

#loading .loading-animation-wrapper .loading-animation:before {
  background-color: var(--loading-foreground-color);
  color: var(--loading-foreground-color);
  content: "";
  border-radius: 12px;
  width: 20px;
  height: 20px;
  animation: 1s infinite alternate loading-animation;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -30px;
}

#loading .loading-animation-wrapper .loading-animation:after {
  background-color: var(--loading-foreground-color);
  color: var(--loading-foreground-color);
  content: "";
  border-radius: 12px;
  width: 20px;
  height: 20px;
  animation: 1s 1s infinite alternate loading-animation;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 30px;
}

@keyframes loading-animation {
  0% {
    background-color: var(--loading-foreground-color);
  }

  50%, 100% {
    background-color: var(--loading-foreground-dark-color);
  }
}

#loading p, #loading li, #loading h1, #loading h2, #loading h3, #loading h4, #loading h5, #loading h6 {
  color: #fff;
  opacity: 1;
  text-align: center;
}

#loading #loading-azure-content {
  overflow-wrap: anywhere;
  flex-direction: column;
  align-items: center;
  width: 30%;
  display: flex;
  overflow: auto;
}

#loading #loading-azure-content button {
  align-self: end;
}

@media screen and (width <= 600px) {
  #loading #loading-azure-content {
    width: 80%;
  }
}

#loading #loading-azure-content .listWrapper {
  overflow: auto;
}

#loading #loading-azure-content .listWrapper ul {
  padding-left: 15px;
  padding-right: 10px;
}

#loading #loading-azure-content .listWrapper ul p {
  text-align: left;
}

#loading #loading-azure-content button {
  margin: 10px 0;
}

#loading #loading-azure-content .valid-entropy-number {
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

#loading #loading-azure-content .azure-validation-number {
  font-size: 35px;
}

#confirm-azure-push-approved {
  color: #6a4bad;
  z-index: 20;
  background: #6a4bade6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#confirm-azure-push-approved button {
  align-self: end;
}

.not-connected {
  color: red;
}

.listWrapper {
  color: #4c338c;
  flex-direction: column;
  font-family: Lato, sans-serif;
  display: flex;
}

:root {
  --base-line-height: 160%;
  --base-font-size: 16px;
  --background-color: #fff;
  --box-shadow: 0 0 1px 0 #0f1a2e26, 0 1px 4px 0 #0f1a2e26;
  --border-radius: 8px;
  --text-color: #0f1a2e;
  --text-color-muted: #5e5e72;
  --font-family: "Lato", sans-serif;
  --input-border-color: #e1e1ea;
  --input-label-color: #778;
  --input-label-font-size: 14px;
  --input-border-width: 2px;
  --input-border-radius: 4px;
  --input-height: 40px;
  --footer-background-color: #0a3b61;
  --footer-link-color: #2dbecd;
  --footer-text-color: white;
  --footer-spacing: 40px 0;
  --header-text-color: white;
  --menu-background-color: #0f69af;
  --menu-background-active-color: #0a3b61;
  --menu-border-color: #0a3b61;
  --menu-boxshadow-color: none;
  --menu-close-icon-color: #2dbecd;
  --menu-link-color: #2dbecd;
  --menu-text-color: white;
  --menu-item-background-color: transparent;
  --card-border: 1px solid #b4b4c1;
  --button-border-radius: 4px;
  --button-font-family: "Lato", sans-serif;
  --button-font-size: 14px;
  --button-label-color: #fff;
  --button-label-color-inverted: #0f1a2e;
  --button-label-hover-color: #fff;
  --button-background-color: #0f69af;
  --button-background-hover-color: #085897;
  --button-border-color: #0f69af;
  --button-border-hover-color: #085897;
  --button-border-accent-color: #0f69af;
  --button-border-width: 2px;
  --button-outline-label-color: #0f69af;
  --button-outline-background-color: white;
  --button-outline-border-color: #0f69af;
  --button-outline-label-hover-color: #085897;
  --button-outline-border-hover-color: #0f69af;
  --button-outline-background-hover-color: #0f69af1a;
  --button-text-transform: none;
  --snackbar-background-color: #ffc832;
  --snackbar-border-radius: 4px;
  --expander-font-family: "Lato", sans-serif;
  --link-color: #0f69af;
  --separator-color: #b4b4c1;
  --checkbox-color: #0f69af;
  --error-background-color: #e61e50;
  --error-background-hover-color: #c81c47;
  --warning-background-color: #ffc832;
  --warning-background-hover-color: #deaf2f;
  --success-background-color: #01884c;
  --success-background-hover-color: #016b3c;
  --info-background-color: #0f69af;
  --error-link-color: #fff;
  --warning-link-color: #0f1a2e;
  --success-link-color: #fff;
  --info-link-color: #fff;
  --loading-background-color: #0a3b6180;
  --loading-foreground-dark-color: #ffffff80;
  --digit-background-color: #f8f8fc;
  --digit-border-default: 2px solid #e1e1ea;
  --digit-border-focus: 2px solid #0f69af;
  --digit-border-radius: 4px;
  --digit-width: 40px;
  --digit-height: 40px;
  --infobox-background-error-color: #fff6f8;
  --infobox-background-warning-color: #fefbf5;
  --infobox-background-info-color: #f4faff;
  --infobox-background-success-color: #f4fbf8;
  --infobox-text-error-color: #e61e50;
  --infobox-text-warning-color: #ffc832;
  --infobox-text-info-color: #0f69af;
  --infobox-text-success-color: #01884c;
  --infobox-border-error: 1px solid #e61e50;
  --infobox-border-warning: 1px solid #ffc832;
  --infobox-border-info: 1px solid #0f69af;
  --infobox-border-success: 1px solid #01884c;
  --infobox-padding: 16px;
  --infobox-box-shadow: 0 0 1px 0 #0f1a2e26, 0 1px 4px 0 #0f1a2e26;
  --infobox-gap: 0;
  --card-group-header-bakground-success-color: #01884c26;
  --card-group-header-bakground-warning-color: #ffc83240;
  --card-group-header-bakground-info-color: #d8eaf8;
  --card-group-background-color-parent: #f8f8fc;
}

.theme_colored {
  --base-line-height: 160%;
  --base-font-size: 16px;
  --background-color: #96d7d2;
  --box-shadow: 0 0 1px 0 #0f1a2e26, 0 1px 4px 0 #0f1a2e26;
  --text-color: #4c338c;
  --input-label-color: black;
  --input-label-font-size: 12px;
  --font-family: "Verdana";
  --input-border-color: transparent;
  --input-border-width: 0;
  --input-border-radius: 5px;
  --input-height: 30px;
  --footer-background-color: #96d7d2;
  --footer-link-color: #4c338c;
  --footer-text-color: #4c338c;
  --footer-spacing: 0 0 20px;
  --menu-background-color: white;
  --menu-border-color: #4c338c;
  --menu-boxshadow-color: 0px 0px 5px 0px #4c338c;
  --menu-close-icon-color: #da3759;
  --menu-link-color: black;
  --menu-text-color: black;
  --menu-item-background-color: #f3f3f3;
  --card-border: none;
  --button-border-radius: 6px;
  --button-font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --button-font-size: 16px;
  --button-label-color: #4c338c;
  --button-label-color-inverted: black;
  --button-label-hover-color: #eb3c96;
  --button-background-color: #f2c24e;
  --button-background-hover-color: #f2c24e;
  --button-border-color: #f2c24e;
  --button-border-hover-color: #eb3c96;
  --button-border-accent-color: #4c338c;
  --button-border-width: 1px;
  --button-outline-label-color: #4c338c;
  --button-outline-background-color: white;
  --button-outline-border-color: #4c338c;
  --button-outline-label-hover-color: #eb3c96;
  --button-outline-border-hover-color: #eb3c96;
  --button-outline-background-hover-color: #fff;
  --button-text-transform: uppercase;
  --snackbar-background-color: #ffc832;
  --snackbar-border-radius: 4px;
  --expander-font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  --link-color: #4c338c;
  --separator-color: #4c338c;
  --checkbox-color: #eb3c96;
  --error-background-color: #da3759;
  --warning-background-color: #f2c24e;
  --success-background-color: #b3d362;
  --info-background-color: #4c338c;
  --error-link-color: #fff;
  --warning-link-color: #000;
  --success-link-color: #000;
  --info-link-color: #fff;
  --loading-background-color: #6a4bade6;
  --loading-foreground-color: #96d7d2;
  --loading-foreground-cark-color: #96d7d280;
  --digit-background-color: #f8f8fc;
  --digit-border-default: 2px solid #e1e1ea;
  --digit-border-focus: 2px solid #4c338c;
  --digit-border-radius: 4px;
  --digit-width: 40px;
  --digit-height: 40px;
  --infobox-background-error-color: #fff;
  --infobox-background-warning-color: #fff;
  --infobox-background-info-color: #fff;
  --infobox-background-success-color: #fff;
  --infobox-text-error-color: #4c338c;
  --infobox-text-warning-color: #4c338c;
  --infobox-text-info-color: #4c338c;
  --infobox-text-success-color: #4c338c;
  --infobox-border-error: none;
  --infobox-border-warning: none;
  --infobox-border-info: none;
  --infobox-border-success: none;
  --infobox-padding: 20px;
  --infobox-box-shadow: 0 0 20px 0 #a6a8a7;
  --infobox-gap: 10px;
  --card-group-header-bakground-success-color: #01884c26;
  --card-group-header-bakground-warning-color: #ffc83240;
  --card-group-header-bakground-info-color: #d8eaf8;
}

html, body, header, main {
  background-color: var(--background-color);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
}

@font-face {
  font-family: Merck-Regular;
  src: url("MerckWeb-Regular.15f01ff3.woff") format("woff");
}

div[class="Merck-Regular"] * {
  font-family: Merck-Regular;
}

body, html {
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid var(--separator-color);
  width: 100%;
}

.muted {
  color: var(--text-color-muted);
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

.cs-main-container {
  flex-direction: column;
  flex: 1;
  justify-content: start;
  align-self: center;
  width: 100%;
  max-width: 906px;
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
}

.cs-main-container-width {
  align-self: center;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
}

.userguide-main-container {
  width: 100%;
}

.main-container-content {
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 906px;
  padding: 0 10px;
  display: flex;
}

.mainColor {
  color: var(--text-color);
}

.center-svg {
  width: 100%;
  max-width: 450px;
  height: 100%;
  min-height: 300px;
  max-height: 500px;
}

.center-svg.cp {
  max-width: 350px;
}

.header-svg {
  flex-direction: column;
  align-content: center;
  align-items: center;
  display: flex;
}
/*# sourceMappingURL=index.975ba316.css.map */
