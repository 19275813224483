@use "../../../../assets/styles/_variables";

nav {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  background-color: var(--menu-background-color);
  height: 50px;

  &.active {
    background-color: var(--menu-background-active-color);

    .navigation-wrapper {
      position: absolute;
      top: 0;
      right: 0;
      height: calc(100vh - 16px);
      border-radius: 0;
      background-color: var(--menu-background-active-color);
      z-index: 1001;

      box-shadow: 0px 0px 5px 0px var(--menu-boxshadow-color);
      width: 100%;
      max-width: 320px;
    }

    .navigation-welcome {
      padding: 24px 32px 0;
      .name {
        font-size: 18px;
        color: var(--menu-text-color);
        margin: 0;
        line-height: 140%;
      }
      .mail {
        font-size: 14px;
        color: var(--menu-text-color);
        margin: 4px 0 0;
        line-height: 140%;
      }
    }

    .navigation-header {
      width: 100%;
    }

    h4 {
      color: var(--menu-text-color);
    }

    .storybook-button {
      margin-top: 40px;
    }
  }

  .navigation-title {
    color: var(--header-text-color);
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img {
    // Prevent text from beeing selected. Happened when navigation has been open and closed fast multiple times
    -ms-user-select: none; /* IE 10 and IE 11 */
    -webkit-user-select: none; /* Safari */
    user-select: none; /* Standard syntax */
  }

  .navigation-menu-heading {
    padding-right: 10px;
    cursor: pointer;

    h4 {
      color: var(--menu-text-color);
    }
  }

  .navigation-header {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    p {
      margin: 0;
      color: var(--menu-text-color);

      &:nth-child(1) {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: 14px;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
      }

      &:nth-child(2) {
        font-size: 8px;
        color: var(--menu-text-color);
      }
    }

    h3,
    h4 {
      margin: 0;
    }

    .toggle-container {
      cursor: pointer;
      display: inline-block;
      height: 24px;
      width: 24px;
      flex-shrink: 0;
      fill: white;

      path {
        stroke: white;
      }
    }
  }

  .navigation-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px 32px;
    gap: 16px;

    .navigation-entry {
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      gap: 8px;
      width: 100%;
      line-height: 32px;

      svg {
        color: var(--menu-link-color);
      }

      p {
        font-size: 14px;
        color: var(--menu-link-color);
      }

      p,
      h5 {
        margin: 0;
      }

      &:hover {
        background: var(--menu-item-background-color);
      }

      .navigation-entry-note {
        display: flex;
        flex-direction: column;

        p:nth-child(2) {
          font-size: 8px;
          color: var(--menu-link-color);
        }
      }

      .toggle-wrapper {
        transform: scale(0.75);
        height: 34px;
        align-self: start;

        .toggle {
          height: 34px;

          .toggle-item {
            margin: 0px;
          }
        }
      }
    }
  }
}
