@use "_variables";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

:root {
  --base-line-height: 160%;
  --base-font-size: 16px;
  --background-color: #fff;
  --box-shadow: 0 0 1px 0 rgba(15, 26, 46, 0.15),
    0 1px 4px 0 rgba(15, 26, 46, 0.15);
  --border-radius: 8px;
  --text-color: #0f1a2e;
  --text-color-muted: #5e5e72;
  --font-family: "Lato", sans-serif;
  --input-border-color: #e1e1ea;
  --input-label-color: #777788;
  --input-label-font-size: 14px;
  --input-border-width: 2px;
  --input-border-radius: 4px;
  --input-height: 40px;
  --footer-background-color: #0a3b61;
  --footer-link-color: #2dbecd;
  --footer-text-color: white;
  --footer-spacing: 40px 0;
  --header-text-color: white;
  --menu-background-color: #0F69AF;
  --menu-background-active-color: #0A3B61;
  --menu-border-color: #0A3B61;
  --menu-boxshadow-color: none;
  --menu-close-icon-color: #2dbecd;
  --menu-link-color: #2dbecd;
  --menu-text-color: white;
  --menu-item-background-color: transparent;
  --card-border: 1px solid #b4b4c1;
  --button-border-radius: 4px;
  --button-font-family: "Lato", sans-serif;
  --button-font-size: 14px;
  --button-label-color: #fff;
  --button-label-color-inverted: #0F1A2E;
  --button-label-hover-color: #fff;
  --button-background-color: #0f69af;
  --button-background-hover-color: #085897;
  --button-border-color: #0f69af;
  --button-border-hover-color: #085897;
  --button-border-accent-color: #0f69af;
  --button-border-width: 2px;
  --button-outline-label-color: #0f69af;
  --button-outline-background-color: white;
  --button-outline-border-color: #0f69af;
  --button-outline-label-hover-color: #085897;
  --button-outline-border-hover-color: #0f69af;
  --button-outline-background-hover-color: #0f69af1a;
  --button-text-transform: none;
  --snackbar-background-color: #ffc832;
  --snackbar-border-radius: 4px;
  --expander-font-family: "Lato", sans-serif;
  --link-color: #0f69af;
  --separator-color: #b4b4c1;
  --checkbox-color: #0f69af;
  --error-background-color: #e61e50;
  --error-background-hover-color: #C81C47;
  --warning-background-color: #ffc832;
  --warning-background-hover-color: #DEAF2F;
  --success-background-color: #01884c;
  --success-background-hover-color: #016B3C;
  --info-background-color: #0f69af;
  --error-link-color: #fff;
  --warning-link-color: #0f1a2e;
  --success-link-color: #fff;
  --info-link-color: #fff;
  --loading-background-color: rgba(10, 59, 97, 0.5);
  --loading-foreground-dark-color: rgba(255, 255, 255, 0.5);
  --digit-background-color: #f8f8fc;
  --digit-border-default: 2px solid #e1e1ea;
  --digit-border-focus: 2px solid #0f69af;
  --digit-border-radius: 4px;
  --digit-width: 40px;
  --digit-height: 40px;
  --infobox-background-error-color: #FFF6F8;
  --infobox-background-warning-color: #FEFBF5;
  --infobox-background-info-color: #F4FAFF;
  --infobox-background-success-color: #F4FBF8;
  --infobox-text-error-color: #E61E50;
  --infobox-text-warning-color: #FFC832;
  --infobox-text-info-color: #0f69af;
  --infobox-text-success-color: #01884C;
  --infobox-border-error: 1px solid #E61E50;
  --infobox-border-warning: 1px solid #FFC832;
  --infobox-border-info: 1px solid #0f69af;
  --infobox-border-success: 1px solid #01884C;
  --infobox-padding: 16px;
  --infobox-box-shadow: 0 0 1px 0 rgba(15, 26, 46, 0.15), 0 1px 4px 0 rgba(15, 26, 46, 0.15);
  --infobox-gap: 0;
  --card-group-header-bakground-success-color: rgba(1, 136, 76, 0.15);
  --card-group-header-bakground-warning-color: rgba(255, 200, 50, 0.25);
  --card-group-header-bakground-info-color: rgba(216, 234, 248);
  --card-group-background-color-parent: #f8f8fc;
}

.theme_colored {
  --base-line-height: 160%;
  --base-font-size: 16px;
  --background-color: #96d7d2;
  --box-shadow: 0 0 1px 0 rgba(15, 26, 46, 0.15),
    0 1px 4px 0 rgba(15, 26, 46, 0.15);
  --text-color: #4c338c;
  --input-label-color: black;
  --input-label-font-size: 12px;
  --font-family: "Verdana";
  --input-border-color: transparent;
  --input-border-width: 0;
  --input-border-radius: 5px;
  --input-height: 30px;
  --footer-background-color: #96d7d2;
  --footer-link-color: #4c338c;
  --footer-text-color: #4c338c;
  --footer-spacing: 0 0 20px;
  --menu-background-color: white;
  --menu-border-color: #4c338c;
  --menu-boxshadow-color: 0px 0px 5px 0px #4c338c;
  --menu-close-icon-color: #da3759;
  --menu-link-color: black;
  --menu-text-color: black;
  --menu-item-background-color: #f3f3f3;
  --card-border: none;
  --button-border-radius: 6px;
  --button-font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --button-font-size: 16px;
  --button-label-color: #4c338c;
  --button-label-color-inverted: black;
  --button-label-hover-color: #eb3c96;
  --button-background-color: #f2c24e;
  --button-background-hover-color: #f2c24e;
  --button-border-color: #f2c24e;
  --button-border-hover-color: #eb3c96;
  --button-border-accent-color: #4c338c;
  --button-border-width: 1px;
  --button-outline-label-color: #4c338c;
  --button-outline-background-color: white;
  --button-outline-border-color: #4c338c;
  --button-outline-label-hover-color: #eb3c96;
  --button-outline-border-hover-color: #eb3c96;
  --button-outline-background-hover-color: #fff;
  --button-text-transform: uppercase;
  --snackbar-background-color: #ffc832;
  --snackbar-border-radius: 4px;
  --expander-font-family: "Nunito Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  --link-color: #4c338c;
  --separator-color: #4c338c;
  --checkbox-color: #eb3c96;
  --error-background-color: #da3759;
  --warning-background-color: #f2c24e;
  --success-background-color: #b3d362;
  --info-background-color: #4c338c;
  --error-link-color: #fff;
  --warning-link-color: #000;
  --success-link-color: #000;
  --info-link-color: #fff;
  --loading-background-color: rgba(106, 75, 173, 0.9);
  --loading-foreground-color: #96d7d2;
  --loading-foreground-cark-color: rgba(150, 215, 210, 0.5);
  --digit-background-color: #f8f8fc;
  --digit-border-default: 2px solid #e1e1ea;
  --digit-border-focus: 2px solid #4c338c;
  --digit-border-radius: 4px;
  --digit-width: 40px;
  --digit-height: 40px;
  --infobox-background-error-color: #fff;
  --infobox-background-warning-color: #fff;
  --infobox-background-info-color: #fff;
  --infobox-background-success-color: #fff;
  --infobox-text-error-color: #4C338C;
  --infobox-text-warning-color: #4C338C;
  --infobox-text-info-color: #4C338C;
  --infobox-text-success-color: #4C338C;
  --infobox-border-error: none;
  --infobox-border-warning: none;
  --infobox-border-info: none;
  --infobox-border-success: none;
  --infobox-padding: 20px;
  --infobox-box-shadow: 0 0 20px 0 #A6A8A7;
  --infobox-gap: 10px;
  --card-group-header-bakground-success-color: rgba(1, 136, 76, 0.15);
  --card-group-header-bakground-warning-color: rgba(255, 200, 50, 0.25);
  --card-group-header-bakground-info-color: rgba(216, 234, 248);
}

html,
body,
header,
main {
  background-color: var(--background-color);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
}

@font-face {
  font-family: "Merck-Regular";
  src: url(../fonts/MerckWeb-Regular.woff) format("woff");
}

div[class="Merck-Regular"] * {
  //font-family: 'Verdana';
  font-family: "Merck-Regular";
}

body,
html {
  margin: 0;
  padding: 0;
}

hr {
  border: 0;
  border-top: 1px solid var(--separator-color);
  width: 100%;
}

.muted {
  color: var(--text-color-muted);
}

* {
  box-sizing: border-box;
  font-family: var(--font-family);
}

.cs-main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-self: center;
  width: 100%;
  max-width: 906px;
  margin-top: 20px;
  padding: 0 20px;
}

.cs-main-container-width {
  width: 100%;
  align-self: center;
  max-width: 600px;
  margin-top: 20px;
}

.userguide-main-container {
  width: 100%;
}

.main-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 906px;
  padding: 0px 10px;
}

.mainColor {
  color: var(--text-color);
}

.center-svg {
  max-width: 450px;
  min-height: 300px;
  max-height: 500px;
  height: 100%;
  width: 100%;

  &.cp {
    max-width: 350px;
  }
}

.header-svg {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
