@use "../../../../assets/styles/_variables";

.card-group {
  border: 1px solid #b4b4c1;
  border-radius: 8px;
  background-color: white;
  padding: 0;

  button {
    border: none;
    background-color: transparent;
    border-radius: none;
    cursor: pointer;
  }
}

.card-group-item {
  border-bottom: 1px solid #b4b4c1 !important;
  padding: 16px;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  &.is-clickable {
    cursor: pointer;
  }

  &.parent {
    background-color: var(--card-group-background-color-parent);

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
  }

  &:last-child {
    border-bottom: none !important;
  }

  .label {
    flex-grow: 0;
    font-size: 16px;
    line-height: 140%;
    font-weight: bold;
  }

  .description {
    color: #5e5e72;
    width: 100%;
    text-align: left;
  }

  .action-wrapper {
    border: none;
    background-color: transparent;
    cursor: pointer;

    .icon {
      color: var(--link-color);
    }
  }

  .icon {
    flex-shrink: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    strong {
      margin-right: 4px;
    }
  }

  ul li p,
  ul li h4 {
    display: inline;
    color: var(--text-color-muted);
  }

  ul li h4 {
    margin-right: 4px;
  }

  .toggle-item {
    margin: 0 !important;
  }
}
